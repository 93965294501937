<template>
  <v-card-text
    v-if="filterConfig"
    :style="{ zIndex: $zIndexConfig.listFilter }"
  >
    <v-row>
      <filterItem
        @triggerFilter="triggerFilter"
        v-for="(config, name) in filterConfig"
        :key="name"
        :name="name"
        :config="config"
        :listKey="listKey"
        :setupItemsCallback="setupItemsCallback"
      ></filterItem>
    </v-row>

    <v-btn
      @click="triggerFilter"
      color="primary"
      class="mr-4 mb-2"
      small
    >
      {{ 'action.start_filter'| t }}
    </v-btn>

    <v-btn
      @click="cleanFilter"
      color="primary"
      outlined
      class="mr-4 mb-2"
      small
    >
      {{ 'action.clean_filter'| t }}
    </v-btn>
  </v-card-text>
</template>

<script>
export default {
  props: {
    listKey: String,
  },
  data: () => ({
    itemsCallback: [],
  }),
  methods: {
    setupItemsCallback(item) {
      this.itemsCallback.push(item)
    },
    async triggerFilter() {
      let filter = {}
      for(const item of this.itemsCallback) {
        if(!item) continue
        if(typeof item.callback != 'function') continue
        item.callback(filter)
      }
      if(window.eagleLodash.isEmpty(filter)) filter = null
      this.startFilter(filter)
    },
    cleanFilter() {
      this.startFilter(null)
    },
    startFilter(filter) {
      if(window.eagleLodash.isEqual(this.listFilter, filter)) return

      this.$nextTick(() => {
        this.$store.dispatch(`list/${this.listKey}/setFilter`, filter)
        this.$nextTick(() => {
          this.listActions.changeIndexCondition()
        })
      })
    },
  },
  computed: {
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`]
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
    filterConfig() {
      return this.$store.getters[`list/${this.listKey}/filterConfig`]
    },
  },
  components: {
    filterItem: () => import('@/components/list/listSearchAndFilter/filterItem.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
